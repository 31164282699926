.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Carousel Dots */
.slick-dots li button:before {
  color: #68a16c; /* Use the primary color */
}

.slick-dots li.slick-active button:before {
  color: #647611; /* Use the secondary color */
}

/* Carousel Arrows */
.slick-prev:before,
.slick-next:before {
  color: #68a16c; /* Use the primary color */
}

/* On hover */
.slick-prev:hover:before,
.slick-next:hover:before {
  color: #647611; /* Use the secondary color */
}

.header-button {
  color: #666666 !important;
  font-size: 1.1rem !important;
}

.header-button:hover {
  /* background-color: 'rgba(104, 161, 108, 0.1)'; */
  color: #647611 !important;
  border-bottom: 1px solid #647611 !important;
}

.button-rounded {
  border-radius: 15px !important;
}
